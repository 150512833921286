<template>
  <v-app>
    <dashboard-core-drawer/>
    <dashboard-core-view/>
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",
  components: {
    DashboardCoreDrawer: () => import("./core/Drawer"),
    DashboardCoreView: () => import("./core/View")
  },
  data() {
    return {
      expandOnHover: false,
    };
  },
};
</script>
