<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">
    <!-- Head Buttons -->
    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">{{ $t('personaldocs_title') }}
        </p>
        <p class="text-subtitle-1">{{ $t('personaldocs_subtitle') }}</p>
      </v-col>
    </v-row>

    <!-- Category -->
    <v-row class="mt-5">
      <v-col cols="12" v-if="(categories ? categories.length : false) && !showForTrustedUser" align="right">
        <v-btn color="primary" @click="categoryDialog = true" :block="$vuetify.breakpoint.smAndDown">
          <v-icon>mdi-plus</v-icon>
          {{ $t('create_new_category') }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-tabs color="primary darken-1" show-arrows :grow="categories.length > 4" @change="getFiles" v-if="(categories ? categories.length : false)" v-model="categoryIndex">
          <v-tab class="white" v-for="c in categories" :key="c.id" @click="category = c.id; medias = null;">
            <v-toolbar class="elevation-0 white" flat dense color="white">
              {{ c.name }}
              <v-btn
                  small
                  icon
                  class="ml-5"
                  v-if="category == c.id && !showForTrustedUser"
                  @click="deleteCategoryConfirmDialog = true; deleteCategoryLoading = c.id"
                  :loading="deleteCategoryLoading == c.id"
                  :disabled="deleteCategoryLoading == c.id"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn small icon v-if="category == c.id && !showForTrustedUser" @click="newCategory = c; categoryDialog = true;">
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </v-toolbar>
          </v-tab>
        </v-tabs>
        <v-col cols="12" align="center" v-else-if="!loading">
          <v-btn large color="white" @click="categoryDialog = true" class="mr-2">
            <span class="primary--text"><v-icon>mdi-plus</v-icon> {{ $t('create_first_category') }}</span>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <!-- Medias -->
    <transition name="slide" mode="out-in">
      <v-row v-if="!loading && (categories ? categories.length : false)" class="mt-10" :key="1">
        <v-col cols="12">
          <div v-if="files ? files.length : false">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">{{ $t('name') }}</th>
                  <th class="text-left">{{ $t('description') }}</th>
                  <th class="text-left">{{ $t('size') }}</th>
                  <th class="text-left">{{ $t('date') }}</th>
                  <th class="text-right">{{ $t('actions') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(file, i) in files" :key="file.id">
                  <td>{{ i + 1 }}</td>
                  <td>{{ file.name }}</td>
                  <td>{{ file.desc }}</td>
                  <td>{{ getSize(file.size) }}</td>
                  <td>{{ $helpers.formatDate(file.created_at, 'DD.MM.YYYY') }}</td>
                  <td class="text-right">
                    <v-btn small text fab color="error" @click="deletePersonaldocsFile(file.id)" :loading="deleteTresorFileLoading == file.id" :disabled="deleteTresorFileLoading == file.id" v-if="!showForTrustedUser">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                    <a v-auth-href :href="file.url">
                      <v-btn small text fab color="primary">
                        <v-icon>mdi-cloud-download-outline</v-icon>
                      </v-btn>
                    </a>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <NoFilesUploaded v-else/>
        </v-col>
        <!-- Add New File Button -->
        <transition name="slide" mode="out-in" v-if="!showForTrustedUser">
          <v-col v-if="category" justify="center" align="center" cols="12" class="mt-10 mb-16">
            <v-btn fab x-large color="primary" :to="{name: 'newPersonaldocs', params: {category_id: category}}">
              <v-icon color="white">mdi-cloud-upload-outline</v-icon>
            </v-btn>
            <p class="text-subtitle-1 mt-5">{{ $t('upload_new_file') }}</p>
          </v-col>
        </transition>
      </v-row>
      <v-row v-else-if="(categories ? categories.length : true)" class="mb-5 mt-5" :key="0">
        <v-col cols="12">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-col>
      </v-row>
    </transition>

    <!-- Delete File Confirm Dialog -->
    <v-dialog
        v-model="deleteConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title>
          {{ $t('are_you_sure') }}
        </v-card-title>
        <v-card-text class="text--primary">
          {{ $t('delete_file_alert') }}
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="deletePersonaldocsFile(selectedMedia)"
              :block="$vuetify.breakpoint.smAndDown"
          >
            {{ $t('yes') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteConfirmDialog = false; selectedMedia = null;"
              :block="$vuetify.breakpoint.smAndDown"
              :class="{
                'ml-0 mt-2': $vuetify.breakpoint.smAndDown
              }"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Create New Category -->
    <v-dialog
        v-model="categoryDialog"
        max-width="500"
        persistent
    >
      <v-card>
        <v-card-title>
          <span v-if="newCategory.id">{{ $t('update_category') }}</span>
          <span v-else>{{ $t('create_new_category') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeCategoryDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-5 text--primary">
          <v-text-field
              outlined
              :label="$t('category_name')"
              maxlength="200"
              counter
              :disabled="newCategoryLoading"
              :loading="newCategoryLoading"
              v-model="newCategory.name"
              @keyup.enter="newCategory.id ? updateCategory() : addNewCategory()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              :block="$vuetify.breakpoint.smAndDown"
              :disabled="!newCategory || newCategoryLoading"
              :loading="newCategoryLoading"
              @click="newCategory.id ? updateCategory() : addNewCategory()"
          >
            <span v-if="newCategory.id">{{ $t('update') }}</span>
            <span v-else>{{ $t('add') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Category Confirm Dialog -->
    <v-dialog
        v-model="deleteCategoryConfirmDialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title>
          {{ $t('are_you_sure') }}
        </v-card-title>
        <v-card-text class="text--primary">
          {{ $t('delete_category_alert') }}
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="deleteCategory"
              :block="$vuetify.breakpoint.smAndDown"
          >
            {{ $t('yes') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteCategoryConfirmDialog = false; deleteCategoryLoading = false;"
              :block="$vuetify.breakpoint.smAndDown"
              :class="{
                'ml-0 mt-2': $vuetify.breakpoint.smAndDown
              }"
          >
            {{ $t('no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <NoSubscriptionDialog/>
  </v-container>
</template>

<script>
import NoSubscriptionDialog from "@/dialogs/NoSubscriptionDialog.vue";
import NoFilesUploaded from "@/widgets/NoFilesUploaded.vue";

export default {
  components: {
    NoFilesUploaded,
    NoSubscriptionDialog,
  },
  data() {
    return {
      loading: true,
      files: null,
      deleteConfirmDialog: false,
      selectedMedia: null,
      deleteTresorFileLoading: false,
      category: null,
      categoryIndex: 0,
      categories: null,
      newCategory: {
        id: null,
        name: null
      },
      categoryDialog: false,
      newCategoryLoading: false,
      deleteCategoryConfirmDialog: false,
      deleteCategoryLoading: false,
      defaultCategories: [
        'Banken',
        'Versicherungen',
        'Immobilien',
        'sonstige Wertanlagen',
        'Rechtsanwalts- und Notarverträge',
        'Vollmachten',
      ],
      lastAction: null,
      showForTrustedUser: this.$store.getters.userIsLoggedInAsTrustedUser
    }
  },
  watch: {
    '$route.name'() {
      this.getMe()
    }
  },
  mounted() {
    this.getMe()
  },
  methods: {
    getMe() {
      this.loading = true
      this.$store.dispatch('getMe')
          .then(() => {
            this.getCategories()
          })
    },
    getFiles() {
      this.loading = true
      this.$store.dispatch('getPersonaldocsFiles', this.category)
          .then(resp => {
            this.files = resp
            this.loading = false
          })
          .catch(err => {
            this.loading = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    deletePersonaldocsFile(id) {
      if (!this.selectedMedia) {
        this.selectedMedia = id
        this.deleteConfirmDialog = true
      } else {
        this.deleteTresorFileLoading = id
        this.fabLoader = id
        this.deleteConfirmDialog = false
        this.$store.dispatch('deletePersonaldocsFile', {id: id})
            .then(() => {
              this.fabLoader = false
              this.deleteTresorFileLoading = false
              this.selectedMedia = null
              this.getFiles()
            })
            .catch(err => {
              this.deleteTresorFileLoading = false
              this.fabLoader = false

              if (err.response.status !== 402) {
                this.$helpers.showError(err)
              }
            })
      }
    },
    getSize(size) {
      let s = Math.floor(size / 1024)
      let format = 'KB'
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'MB'
      }
      if (s >= 1024) {
        s = Math.floor(s / 1024)
        format = 'GB'
      }
      return `${s} ${format}`
    },
    getCategories() {
      this.$store.dispatch('getCategories', 'personaldocs')
          .then(resp => {
            this.categories = resp
            if (resp.length && !this.category) {
              if (this.$route.params.category_id) {
                for (let index = 0; index < resp.length; index++) {
                  const cat = resp[index];
                  if (cat.id == this.$route.params.category_id) this.categoryIndex = index
                }
                this.category = this.$route.params.category_id
              } else {
                this.category = resp[0].id
                this.categoryIndex = 0
              }
              this.getFiles()
            }

            // Create Default Categories
            if (!resp.length) {
              for (let index = 0; index < this.defaultCategories.length; index++) {
                const cat = this.defaultCategories[index];
                this.$store.dispatch('storeCategories', {
                  type: 'personaldocs',
                  name: cat
                })
                if (index == this.defaultCategories.length - 1) this.getCategories()
              }
            } else {
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
          })
    },
    addNewCategory() {
      this.newCategoryLoading = true
      this.$store.dispatch('storeCategories', {
        type: 'personaldocs',
        name: this.newCategory.name
      })
          .then(() => {
            this.newCategory = {
              id: null,
              name: null
            }
            this.newCategoryLoading = false
            this.categoryDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.newCategoryLoading = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    updateCategory() {
      this.newCategoryLoading = true
      this.$store.dispatch('updateCategories', this.newCategory)
          .then(() => {
            this.newCategory = {
              id: null,
              name: null
            }
            this.newCategoryLoading = false
            this.categoryDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.newCategoryLoading = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    deleteCategory() {
      this.$store.dispatch('deleteCategories', {
        id: this.category,
        type: 'personaldocs'
      })
          .then(() => {
            this.category = null
            this.deleteCategoryLoading = false
            this.deleteCategoryConfirmDialog = false
            this.getCategories()
          })
          .catch(err => {
            this.deleteCategoryLoading = false
            this.deleteCategoryConfirmDialog = false

            if (err.response.status !== 402) {
              this.$helpers.showError(err)
            }
          })
    },
    closeCategoryDialog() {
      this.newCategory = {
        id: null,
        name: null
      }

      this.categoryDialog = false
    }
  }
}
</script>
